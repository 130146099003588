.main-item {
    padding: 10px;
    background-color: #f6f7f8;
    width: 900px;
}

.background-masker {
    background-color: rgb(240, 240, 240);
    position: absolute;
}

.btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0
    }
    100% {
        background-position: 800px 0
    }
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    width: auto;
}

.static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
}

.shared-dom {
    width: 800px;
    height: 110px;
}

.sub-rect {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    float: left;
    margin: 20px 20px 20px 0;
}

.pure-background {
    background-color: #eee;
}

.css-dom:empty {
    width: 280px;
    height: 220px;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    background-image: radial-gradient(circle 16px, lightgray 99%, transparent 0), linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray), linear-gradient(#fff, #fff);
    background-size: 32px 32px, 200px 32px, 180px 32px, 230px 16px, 100% 40px, 280px 100%;
    background-position: 24px 30px, 66px 30px, 24px 90px, 24px 142px, 0 180px, 0 0;
}

.classic-4 {
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    clip-path: inset(0 3ch 0 0);
    animation: c4 1s steps(4) infinite;
}

@keyframes c4 {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}