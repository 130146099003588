.item .card2 {
    background-color: #193863 !important;
    color: #ffffff !important;
}

.item .card2 h4 {
    color: #eef4ed !important;
}

.list-sp {
    /* padding-bottom:10px !important;  */
    height: 210px
}

.hover-2 {
    --c: #ffffff;
    /* the color */
    --b: .1em;
    /* border length*/
    --d: 10px;
    /* the cube depth */
    --_s: calc(var(--d) + var(--b));
    color: var(--c);
    /* width: 100%; */
    /* height: 60px; */
    border: solid #0000;
    border-width: var(--b) var(--b) var(--_s) var(--_s);
    background: conic-gradient(at left var(--d) bottom var(--d), #0000 90deg, rgb(255 255 255 /0.3) 0 225deg, rgb(255 255 255 /0.6) 0) border-box, conic-gradient(at left var(--_s) bottom var(--_s), #0000 90deg, var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b)) border-box;
    transform: translate(calc(var(--d)/-1), var(--d));
    clip-path: polygon( var(--d) 0%, var(--d) 0%, 100% 0%, 100% calc(100% - var(--d)), 100% calc(100% - var(--d)), var(--d) calc(100% - var(--d)));
    transition: 0.5s;
}

.hover-2:hover {
    transform: translate(0, 0);
    clip-path: polygon( 0% var(--d), var(--d) 0%, 100% 0%, 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, 0% 100%);
}

.img-client {
    height: 230px;
    width: 100%;
}