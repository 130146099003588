.home-banner {
    background-image: url('/public/assets/images/home/home-banner1.png');
    width: 100%;
    height: 680px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@media only screen and (max-width: 600px) {
    .slidfix {
        margin-top: 100px
    }
}

.btn-3 {
    margin-left: 97px !important;
}

@media (max-width:765px) {
    .btn-3 {
        margin-left: 0px !important;
    }
}

.pre-title {
    color: #193863 !important;
    font-weight: 600;
    margin-top: 50px;
}

.pre-title-2 {
    font-size: 0.8em;
    color: #111117 !important;
}

.items .card {
    background-color: #193863;
}

.items .card:hover {
    box-shadow: rgba(0, 0, 0, 0.808) 0px 5px 17px !important;
}

.list-about {
    list-style: none;
}

.list-about li p {
    display: inline !important;
}

.list-about li:hover {
    color: #164588;
    padding-left: 10px;
    transition: padding 0.5s;
}

.list-about i {
    font-size: 22px;
}

.list-about li {
    color: #21333e;
    font-size: 18px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

@media (min-width: 765px) {
    .zoom-effect-container {
        position: relative;
        width: auto;
        height: 360px;
        margin: 0 auto;
        overflow: hidden;
    }
    .image-card {
        position: absolute;
        top: 0;
        left: 0;
    }
    .image-card img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
    }
    .zoom-effect-container:hover .image-card img {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
    }
}

.why-choose-subtitle {
    background: #164588 !important;
    color: white !important;
    padding: 10px !important;
    border-radius: 5px !important;
}

.why-choose-bg {
    background-color: #111117 !important;
}

.us-subtitle span {
    color: #ffffff !important;
}

.awards-card {
    background-color: #fff !important;
    height: 500px !important;
    margin-bottom: 70px !important;
    z-index: 1;
}

.awards-card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    height: 640px !important;
}

.awards-card h5 {
    color: black !important;
    text-align: center !important;
}

.awards-card .award-title {
    color: #193863 !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.awards-card .award-year {
    color: black !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.awards-card img {
    width: 60% !important;
    height: 280px !important;
}

.awards-card .content {
    position: relative !important;
    top: 0px !important;
    padding: 10px 15px !important;
    color: #111 !important;
    text-align: justify !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: 0.3s ease-in-out !important;
}

.awards-card:hover .content {
    /* margin-top: 30px !important; */
    visibility: visible !important;
    opacity: 1 !important;
    transition-delay: 0.2s !important;
}

.awards-card .content .contact-title {
    color: #000 !important;
}

.responsive1-img {
    background-image: url('/public/assets/images/home/respo-banner.png');
    width: 100%;
    height: 680px;
    background-size: 100% 100%;
    position: absolute;
    background-repeat: no-repeat;
    opacity: 0.5;
}

.responsive2-img {
    background-image: url('/public/assets/images/home/banner-md.png');
    width: 100%;
    height: 680px;
    background-size: 100% 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.5;
}

.responsive-lg-img {
    background-image: url('/public/assets/images/home/home_banner.png');
    width: 100%;
    height: 680px;
    background-size: 100% 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.5;
}

@media (max-width:1199px) and (min-width:776px) {
    .pre-title-2 {
        font-size: 0.5em;
    }
}

.btn-service {
    border: 2px solid #ffffff;
    color: #fff;
    border-radius: 5px;
}

.btn-service:hover {
    background-color: #ffffff;
    color: #164588 !important;
    font-weight: bold !important;
    border-radius: 50px;
    transition: ease-in 0.3s;
}

.why-choose-subtitle:hover {
    border: #fff solid 2px;
    border-radius: 50px !important;
    transition: ease-in 0.3s;
}

.smooth-anchor i {
    color: #ffffff !important;
    background-color: #164588 !important;
}