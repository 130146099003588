  .services-bg {
      background-color: #eef4ed !important;
  }
  
  .item .card1 {
      background-color: #193863 !important;
  }
  
  .item .card i {
      color: #ffffff !important;
  }
  
  .item .card p {
      color: #ffffff !important;
  }
  
  .services-title-2 {
      color: #21333e !important;
      text-align: center;
  }
  
  .services-img-endtoend {
      height: 500px !important;
  }
  
  .img-engagement {
      height: 500px !important;
  }
  
  .services-card {
      background-color: #fff;
  }
  
  .services-card:hover {
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }
  
  .map-img {
      width: 900px;
  }
  
  .pre-title {
      font-size: 22px;
  }