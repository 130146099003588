.contacts h4 {
    color: #21333e !important;
}

.contact-main-title {
    color: #21333e !important;
}

.contact-details {
    font-size: 1rem !important;
}

.text {
    margin-bottom: 16px !important;
}