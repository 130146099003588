.search-box1 {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.search-box1 .input-search1 {
    /* border-style: none; */
    background-color: transparent;
    border: 1px solid #164588;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
}

.search-box1 .input-search1:focus {
    border-radius: 0px;
    background-color: transparent;
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.input-search1::placeholder {
    color: #164588;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
}

.search-box1 .search_icon {
    color: #fff;
    font-size: 20px;
    margin-left: 10px;
    position: relative;
    padding: 20px;
    background-color: #164588;
    border-radius: 70px !important;
    border: #164588 2px solid !important;
}

.search-box1 .search_icon i {
    position: absolute;
    left: 25% !important;
}

.search-box1 .search_icon:hover {
    color: #164588;
    background-color: #fff;
    border: #164588 2px solid;
}

.search-box1 .search_icon:focus {
    border: #164588 2px solid !important;
}

.step {
    position: relative;
    min-height: 1em;
    color: gray;
}

.step+.step {
    margin-top: 1.5em
}

.step>div:first-child {
    position: static;
    height: 0;
}

.step>div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
}

.step.step-active {
    color: #164588
}

.step.step-active .circle {
    background-color: #1a5bb6;
}


/* Circle */

.circle {
    background: gray;
    position: relative;
    width: 35px;
    height: 35px;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
}

.circle i {
    padding: 7px;
    font-size: 18px;
}


/* Vertical Line */

.circle:after {
    content: '';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 3%;
    left: 45%;
    height: 100%;
    width: 4px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgb(155, 190, 240);
    /* background-color: rgba(0, 0, 0, 0.25); */
    z-index: -1;
}

@media (max-width: 767px) {
    .circle:after {
        display: none;
    }
}

.step:last-child .circle:after {
    display: none
}

.title-prog {
    padding-left: 10px;
    font-size: 20px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .title-prog {
        font-size: 15px;
    }
    .caption {
        font-size: 12px;
    }
    .list-item {
        display: block!important;
    }
    .list-item li {
        padding: 0 !important;
        line-height: 11px;
    }
}

.caption {
    color: #000;
    padding-left: 12px;
    font-weight: 500;
}

.caption b {
    color: #164588;
}

.list-item li {
    list-style: none;
    padding: 2px;
}