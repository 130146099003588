.banner-img {
    height: 480px !important;
}

@media (max-width: 768px) {
    .banner-img {
        height: 350px !important;
    }
}

.btn-vessel:hover {
    border-radius: 30px;
    transition: ease-in 0.3s;
    background-color: #0a4ba7;
    /* list-style: ; */
}

.list-none {
    list-style: none;
}

.dropdown1 {
    position: relative;
    display: block;
}

.dropdown-content1 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* z-index: 1; */
    margin-left: 20PX !important;
}

.menu-li .nav-items .nav-link {
    text-align: center !important;
    visibility: collapse;
}

.dropdown-menu ul {
    list-style: none;
}

.span {
    /* padding: 5px; */
    font-size: 17px;
    cursor: pointer;
    display: block;
    font-weight: bold;
}

.span::after {
    float: right;
    right: 10%;
    content: "\25be";
}

.slide {
    clear: both;
    width: 100%;
    height: 0px;
    overflow: hidden;
    /* text-align: center; */
    transition: height .4s ease;
}

.slide li {
    padding: 5px;
    margin-left: 50px !important;
    list-style: none;
    color: #164588;
    font-weight: 700 !important;
}

.menu2 li {
    color: #164588;
}

.menu2 li .nav-link {
    color: #164588 !important;
    padding: 5px;
    font-weight: bold !important;
}

#touch {
    position: absolute;
    opacity: 0;
    height: 0px;
}

#download {
    position: absolute;
    opacity: 0;
    height: 0px;
}

#touch:checked+.slide {
    height: auto;
}

#download:checked+.slide {
    height: auto;
}

#menu__toggle {
    opacity: 0;
}

#menu__toggle:checked~.menu__btn>span {
    transform: rotate(45deg);
}

#menu__toggle:checked~.menu__btn>span::before {
    top: 0;
    transform: rotate(0);
}

#menu__toggle:checked~.menu__btn>span::after {
    top: 0;
    transform: rotate(90deg);
}

#menu__toggle:checked~.menu__box {
    visibility: visible;
    left: 0;
}

.menu__btn {
    display: flex;
    align-items: center;
    position: fixed;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #616161;
    transition-duration: .25s;
}

.menu__btn>span::before {
    content: '';
    top: -8px;
}

.menu__btn>span::after {
    content: '';
    top: 8px;
}

.menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #ECEFF1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
    transition-duration: .25s;
}

.menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
}

.menu__item:hover {
    background-color: #CFD8DC;
}

.mbmenu {
    position: absolute;
    display: fixed;
    z-index: 1000;
    width: 100% !important;
    height: 1000px !important;
}