table td,
table th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.card {
    border-radius: .5rem;
}

.vtilte {
    background-color: #164588;
    color: #f9f9f9;
}

.sbtn {
    background-color: #E0F8E0;
    overflow: hidden;
    position: relative;
    color: #4FDB36;
    cursor: auto;
    width: 100%
}

.sbtn span {
    z-index: 1;
    position: relative;
    font-weight: 600;
}

.sbtn::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 740%;
    background: green;
    transition: 3s;
    animation: animate 4s linear infinite;
    color: #4FDB36;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sbtn::after {
    content: "";
    position: absolute;
    background-color: #E0F8E0;
    /* background-color: #F0F9EF; */
    inset: 3px;
    color: #4FDB36 !important;
}

.dbtn {
    background-color: #F8E0E0;
    overflow: hidden;
    position: relative;
    cursor: auto;
    width: 100%;
}

.dbtn span {
    z-index: 1;
    position: relative;
    font-weight: 600;
}

.dbtn::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 740%;
    background: red;
    transition: 3s;
    animation: animate1 4s linear infinite;
}

@keyframes animate1 {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.dbtn::after {
    content: "";
    position: absolute;
    background-color: #F8E0E0;
    /* background-color: #F0F9EF; */
    inset: 4px;
}

.text-success {
    z-index: 1;
}

.text-danger {
    z-index: 1;
}

.search-box {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.search-box .input-search {
    width: 250px;
    /* border-style: none; */
    background-color: transparent;
    border: 1px solid #164588;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
}

.search-box .input-search:focus {
    width: 255px;
    border-radius: 0px;
    background-color: transparent;
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.input-search::placeholder {
    color: #164588;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 700;
}

.wrapper {
    box-sizing: border-box;
    display: inline-flex;
    /* background: #21333e; */
    /* padding: 15px; */
    width: 400px;
    /* min-width: 20%; */
    align-items: center;
    /* justify-content: space-evenly; */
    /* border-radius: 5px; */
    /* padding: 20px 15px; */
    /* box-shadow: 5px 5px 30px rgba(0,0,0,0.2); */
}

@media (max-width: 504px) {
    .wrapper {
        display: table-cell;
        position: relative;
        /* display: inline-block; */
        /* position: relative; */
    }
    .wrapper .option {
        display: flex;
        /* justify-content: flex-start; */
        /* justify-content: center;   */
        margin-left: 30px;
    }
}

.wrapper .option {
    /* background: #111117; */
    height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content:center; */
    /* min-width: 10%; */
    /* border-radius: 5px; */
    cursor: pointer;
    /* padding: 0 10px; */
    /* border: 2px solid lightgrey; */
    transition: all 0.3s ease;
}

.wrapper .option .dot {
    height: 21px;
    width: 21px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 13px;
    height: 13px;
    background: #fafafa;
    /* border: 2px solid #164588; */
    /* box-shadow: 50px 20px 40px #0069d9; */
    border-radius: 50%;
    opacity: 0;
    /* padding: 0 10px; */
    transform: scale(1.5);
    transition: all 0.3s ease;
}

input[type="radio"] {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-3:checked:checked~.option-3,
#option-2:checked:checked~.option-2 {
    border-color: #0069d9;
    color: #0069d9;
}

#option-1:checked:checked~.option-1 .dot,
#option-3:checked:checked~.option-3 .dot,
#option-2:checked:checked~.option-2 .dot {
    background: #1565C0;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-3:checked:checked~.option-3 .dot::before,
#option-2:checked:checked~.option-2 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.wrapper .option span {
    font-size: 17px;
    color: #A4A4A4;
    font-weight: 500;
    transform-origin: 1s;
    padding-left: 6px;
}

#option-1:checked:checked~.option-1 span,
#option-3:checked:checked~.option-3 span,
#option-2:checked:checked~.option-2 span {
    color: #164588;
    font-weight: bolder;
}

.btn-search {
    border-radius: 5px !important;
    background-color: #164588;
    color: #ffffff;
    transition: cubic-bezier(0.23, 1, 0.320, 1);
    font-weight: 600;
    /* position: absolute; */
}

.btn-search:hover {
    border-radius: 50px !important;
    color: #1565C0;
    border: 2px solid #1565C0;
    font-weight: 600;
    transition: ease-out 7ms;
    background-color: #ffffff;
}

.search-input .icon {
    position: relative;
    display: inline;
    background-color: #164588;
    padding: 8px;
    left: 0;
    top: 0;
    border-radius: 80%;
    font-size: 20px;
    cursor: pointer;
    color: #ffffff;
}

.search-input .autocom-box {
    padding: 0;
    opacity: 0;
    pointer-events: none;
    max-height: 280px;
    overflow-y: auto;
}

.search-input.active .autocom-box {
    padding: 10px 8px;
    opacity: 1;
    pointer-events: auto;
}

.autocom-box li {
    list-style: none;
    padding: 8px 12px;
    display: none;
    width: 90%;
    cursor: default;
    border-radius: 3px;
}

.search-input.active .autocom-box li {
    display: block;
}

.autocom-box li:hover {
    background: #efefef;
}

.text-primary {
    color: #164588 !important;
}

.card-vessel {
    background-color: #ffffff;
    padding: 20px;
    justify-content: center;
}

.search-box .search_icon {
    color: #fff;
    font-size: 20px;
    margin-left: 10px;
    position: relative;
    padding: 20px;
    background-color: #164588;
    border-radius: 70px !important;
    border: #164588 2px solid !important;
}

.search-box .search_icon i {
    position: absolute;
    left: 25% !important;
}

.search-box .search_icon:hover {
    color: #164588;
    background-color: #fff;
    border: #164588 2px solid;
}

.search-box .search_icon:focus {
    border: #164588 2px solid !important;
}

.search-box1 {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.search-box1 .input-search1 {
    /* border-style: none; */
    background-color: transparent;
    border: 1px solid #164588;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
}

.search-box1 .input-search1:focus {
    border-radius: 0px;
    background-color: transparent;
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.input-search1::placeholder {
    color: #164588;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
}

.table-row td {
    font-weight: 500;
    color: #164588;
}